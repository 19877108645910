
<template>
  <div class="body">
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr />
      <el-tabs
        tab-position="left"
        style="min-height: 200px; margin: 50px 5px 50px 0"
        @tab-click="showCharts"
        type="border-card"
      >
        <el-tab-pane label="国家人力资本化展示">
          <el-col
            :span="2"
            v-if="targetName === '国家人力资本化展示'"
            style="
              width: 100%;
              height: 50%;
              background-color: #f5f5f5;
              float: left;
            "
          >
            &nbsp; <span class="title-chart"> 国家人力资本化展示 </span>
            <div class="main" style="width: 90%; height: 20px">
              &nbsp;
              <span
                style="font-size: 16px; font-weight: bolder; color: #0b1013"
              >
                数据类型选择：
              </span>
              <template>
                <el-select
                  v-model="Comprehensive.comprehensivetypeName"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in comprehensivedata"
                    :key="item.id"
                    :label="item.typeName"
                    :value="item.id"
                    @click.native="setComprehensiveId(item.id, item.typeName)"
                  >
                  </el-option>
                </el-select>
              </template>
              &nbsp;
              <span
                style="font-size: 16px; font-weight: bolder; color: #0b1013"
              >
                年份选择：
              </span>
              <template>
                <el-select v-model="year" placeholder="请选择">
                  <el-option
                    v-for="item in allyear"
                    :key="item"
                    :label="item"
                    :value="item"
                    @click.native="setyear(item)"
                  >
                  </el-option>
                </el-select>
              </template>
            </div>
            <div
              id="main3"
              class="main"
              style="width: 90%; height: 400px"
            ></div>
            选择查看的国家:
            <el-select
              v-model="checkedCities"
              filterable
              collapse-tags
              required="true"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in countriess"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <!-- <el-button type="info" icon="el-icon-s-opportunity" circle @click="dialogVisible = true">查看更多国家</el-button> -->
            <!-- <el-dialog title="国家选择" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
            style="text-align:left">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
            </el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="city in countriess" :label="city" :key="city">{{ city }}</el-checkbox>
            </el-checkbox-group>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="reloadC()">确 定</el-button>
            </span>
          </el-dialog> -->
            <div
              id="main"
              class="main"
              :style="{
                height: checkedCities.length > 15 ? '1200px' : '800px',
              }"
            ></div>
            <!-- <div id="bmain" v-if="checkedCities.length > 20" class="main" style="height:3000px;width:95%;"></div> -->
          </el-col>
        </el-tab-pane>
        <el-tab-pane label="区域人力资本化展示">
          <el-col
            :span="2"
            style="width: 100%; height: 50%; background-color: #f5f5f5"
            v-if="targetName === '区域人力资本化展示'"
          >
            &nbsp; <span class="title-chart"> 区域人力资本化展示 </span>
            <div
              id="main2"
              class="main"
              style="width: 100%; height: 750px"
            ></div>
            <!-- <div id="main4" class="main" style="width: 90%;height:200px;"></div> -->
          </el-col></el-tab-pane
        >
        <el-tab-pane label="行业人力资本化展示">
          <el-col
            :span="2"
            style="width: 100%; height: 50%; background-color: #f5f5f5"
            v-if="targetName === '行业人力资本化展示'"
          >
            &nbsp; <span class="title-chart"> 行业人力资本化展示 </span>
            <div
              id="main5"
              class="main"
              style="width: 90%; height: 200px"
            ></div>
            <div
              id="main6"
              class="main"
              style="width: 90%; height: 200px"
            ></div>
          </el-col>
        </el-tab-pane>
        <el-tab-pane label="高校人力资本化展示">
          <el-col
            :span="2"
            style="width: 100%; height: 50%; background-color: #f5f5f5"
            v-if="targetName === '高校人力资本化展示'"
          >
            &nbsp; <span class="title-chart"> 高校人力资本化展示 </span>
            <div
              id="main7"
              class="main"
              style="width: 90%; height: 200px"
            ></div>
            <div
              id="main8"
              class="main"
              style="width: 90%; height: 200px"
            ></div>
          </el-col>
        </el-tab-pane>
        <el-tab-pane label="数据下载">
          <el-col :span="4" class="download" v-if="targetName === '数据下载'">
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '国家人力资本化指数全貌';
                "
                icon="el-icon-download"
                >国家人力资本化指数全貌（收费下载）
              </el-link>
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '国家排名';
                "
                icon="el-icon-download"
                >国家排名（免费下载）</el-link
              >
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '全球人力资本化指数报告';
                "
                icon="el-icon-download"
                >全球人力资本化指数报告（免费下载）
              </el-link>
            </div>
            <div class="downTitle" style="width: 90%; height: 50px">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '国家人力资本化指数报告';
                "
                icon="el-icon-download"
                >国家人力资本化指数报告（收费下载）
              </el-link>
            </div>
            <div class="downTitle" style="width: 90%; height: 50px">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '人力资本化指数趋势报告';
                "
                icon="el-icon-download"
                >人力资本化指数趋势报告（收费下载）
              </el-link>
            </div>
          </el-col>
          <el-col class="download" :span="4">
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '人力资本化指数全貌';
                "
                icon="el-icon-download"
                >人力资本化指数全貌（收费下载）</el-link
              >
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '区域等排名';
                "
                icon="el-icon-download"
                >区域等排名（免费下载）</el-link
              >
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '区域人力资本化指数报告';
                "
                icon="el-icon-download"
                >区域人力资本化指数报告（免费下载）
              </el-link>
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '省市人力资本化指数报告';
                "
                icon="el-icon-download"
                >省市人力资本化指数报告（收费下载）
              </el-link>
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '城市人力资本化指数报告';
                "
                icon="el-icon-download"
                >城市人力资本化指数报告（收费下载）
              </el-link>
            </div>
          </el-col>
          <el-col class="download" :span="4">
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '指数全貌';
                "
                icon="el-icon-download"
                >指数全貌（收费下载）</el-link
              >
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '企业排名';
                "
                icon="el-icon-download"
                >企业排名（免费下载）</el-link
              >
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '各行业人力资本化指数报告';
                "
                icon="el-icon-download"
                >各行业人力资本化指数报告（收费下载）
              </el-link>
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '核心企业人力资本化指数报告';
                "
                icon="el-icon-download"
                >核心企业人力资本化指数报告（收费下载）
              </el-link>
            </div>
          </el-col>
          <el-col class="download" :span="4">
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '高校人力资本化指数全貌';
                "
                icon="el-icon-download"
                >高校人力资本化指数全貌（收费下载）
              </el-link>
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '高校排名';
                "
                icon="el-icon-download"
                >高校排名（免费下载）</el-link
              >
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '全球高校人力资本化指数报告';
                "
                icon="el-icon-download"
                >全球高校人力资本化指数报告（收费下载）
              </el-link>
            </div>
            <div class="downTitle">
              <el-link
                @click="
                  drawer = true;
                  downloadname = '各高校人力资本化指数报告';
                "
                icon="el-icon-download"
                >各高校人力资本化指数报告（收费下载）
              </el-link>
            </div>
          </el-col>
        </el-tab-pane>
      </el-tabs>
      <el-row style="text-align: center; margin: 20px auto; width: 90%">
      </el-row>
      <el-drawer
        :before-close="handleClose"
        :visible.sync="drawer"
        direction="ttb"
        custom-class="demo-drawer"
        ref="drawer"
      >
        <el-col :lg="24" v-if="this.userData == null">
          <el-result
            icon="warning"
            title="登录提示"
            subTitle="请登录后进行操作"
          >
            <template slot="extra">
              <el-button type="primary" round @click="cancelForm" size="medium"
                >返回</el-button
              >
            </template>
          </el-result>
        </el-col>
        <div
          v-else
          style="
            width: 95%;
            height: 80%;
            text-align: left;
            margin: 2% 0 20px 2%;
          "
        >
          &nbsp;
          <span
            v-if="downloadname !== '人力资本化指数趋势报告'"
            style="font-size: 16px; font-weight: bolder; color: #0b1013"
          >
            年份选择：
          </span>
          &nbsp;<el-button
            v-if="downloadname !== '人力资本化指数趋势报告'"
            round
            @click="postallyear"
            >年份全选</el-button
          >
          &nbsp;<el-button
            v-if="downloadname !== '人力资本化指数趋势报告'"
            round
            @click="clearallyear"
            >清空选项</el-button
          >
          <template v-if="downloadname !== '人力资本化指数趋势报告'">
            &nbsp;
            <el-select
              v-model="selectIndexEntity.particular"
              collapse-tags
              required="true"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in allyear"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </template>
          <el-radio-group
            required="true"
            v-model="selectIndexEntity.name"
            size="mini"
            style="margin-top: 10px"
          >
            &nbsp;
            <span style="font-size: 16px; font-weight: bolder; color: #0b1013">
              国家选择：
            </span>
            <el-radio
              border
              v-for="city in countriess"
              :label="city"
              :key="city"
              style="margin: 5px"
            ></el-radio>
          </el-radio-group>
          <div style="height: 60px; margin: 10px 0 20px 0; text-align: center">
            <el-button round @click="cancelForm">取 消</el-button>
            <el-button
              type="primary"
              round
              @click="
                setdownload(
                  selectIndexEntity.particular,
                  selectIndexEntity.name
                )
              "
              :loading="loading"
              >{{ loading ? "提交中 ..." : "确 定" }}</el-button
            >
          </div>
        </div>
      </el-drawer>
    </div>
    <flooter></flooter>
  </div>
</template>

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Sideframe from "../../components/sideframe.vue";
import * as echarts from "echarts";
import Cookies from "js-cookie";
import "echarts-gl";
import china from "../../assets/Json/china.json";
import world from "../../assets/Json/world.json";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";

export default {
  components: { Header, Flooter, Sideframe, Breadcrumb },
  data() {
    return {
      titleData: {
        titleName: "人力资本化指数",
        data: [
          {
            id: nanoid(),
            name: "人力资本化",
            href: "",
          },
          {
            id: nanoid(),
            name: "人力资本化指数",
            href: "",
          },
        ],
      },
      heightTextureSrc: require("@/assets/image/world.topo.bathy.200401.jpg"),
      environmentSrc: require("@/assets/image/starfield.jpg"),
      selectIndexEntity: {
        name: "",
        particular: [],
      },
      pid: 0,
      renderComponent: true,
      checkAll: false,
      dialogVisible: false,
      downloadname: "",
      checkedCities: [
        "美国",
        "中国",
        "法国",
        "德国",
        "日本",
        "俄罗斯",
        "英国",
        "加拿大",
      ],
      isIndeterminate: true,
      drawer: false,
      direction: "btt",
      Comprehensive: {
        comprehensivetypeName: "人力资源基础维度",
        comprehensiveId: 2,
      },
      indexEntity: {
        comprehensive: "",
        comprehensiveId: 2,
        name: "",
        countryEName: "",
        id: 0,
        value: 0,
        particular: "1980",
      },
      targetName: "国家人力资本化展示",
      worlddata: [],
      countriess: [],
      userData: [],
      allyear: [],
      year: "1980",
      value: "",
      comprehensivedata: [],
      loading: false,
      childdata: {
        name: "",
        pid: "",
        comprehensivetypeName: "",
      },
    };
  },
  watch: {
    checkedCities(newVal) {
      this.$nextTick(() => {
        this.getSCE();
      });
    },
  },
  // 创建完毕状态(里面是操作)
  created() {
    this.gettoken();
    this.getuserData();
    this.getComprehensivedata();
  },
  // 挂载结束状态(里面是操作)
  mounted() {
    this.$nextTick(() => {
      this.getSCE();
      this.getworld();
    });
  },
  beforeDestroy() {},
  // 里面的函数只有调用才会执行
  methods: {
    showCharts(targetName) {
      this.targetName = targetName.label;
      if (targetName.label === "国家人力资本化展示") {
        this.$nextTick(() => {
          this.getSCE();
          this.getworld();
        });
      } else if (targetName.label === "区域人力资本化展示") {
        this.$nextTick(() => {
          this.getradar();
        });
      } else if (targetName.label === "行业人力资本化展示") {
      } else if (targetName.label === "高校人力资本化展示") {
      }
    },
    async setdownload(done, name) {
      this.selectIndexEntity.particular = done;
      this.selectIndexEntity.name = name;
      if (this.userData == null) {
        this.$message({
          showClose: true,
          message: "请登录！",
          type: "info",
        });
      } else if (this.downloadname == "国家人力资本化指数全貌") {
        this.$message({
          showClose: true,
          message: "开始下载，请稍等",
          type: "success",
        });
        const res = await this.axios.post(
          "/humanCapital/excelDownload",
          this.selectIndexEntity
        );
        if (res) {
          console.log(res);
          // const blob = new Blob([res], {
          //   type:
          //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
          // }); //type这里表示xlsx类型
          const downloadElement = document.createElement("a");
          const href = "data:application/vnd.ms-excel;base64," + res; //创建下载的链接
          downloadElement.href = href;
          downloadElement.download =
            done +
            "年" +
            this.selectIndexEntity.name +
            this.downloadname +
            ".xls"; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        } else if (res == null) {
          this.$message({
            message: "下载失败,有未选择选项！",
            type: "error",
            showClose: true,
          });
        }
      } else if (this.downloadname == "人力资本化指数趋势报告") {
        this.$message({
          showClose: true,
          message: "开始下载，请稍等",
          type: "success",
        });
        const res = await this.axios.get("/humanCapital/wordDownload", {
          params: {
            pdfName: this.selectIndexEntity.name,
          },
        });
        if (res) {
          const downloadElement = document.createElement("a");
          const href = "data:application/pdf;base64," + res; //创建下载的链接
          downloadElement.href = href;
          downloadElement.download =
            this.selectIndexEntity.name + this.downloadname + ".pdf"; //下载后文件名

          downloadElement.click(); //点击下载
          downloadElement.remove();
          window.URL.revokeObjectURL(href); //释放掉blob对象
        } else if (res == null) {
          this.$message({
            message: "下载失败,有未选择选项！",
            type: "error",
            showClose: true,
          });
        }
      } else {
        this.$message({
          message: "还没有数据可供下载",
          type: "info",
          showClose: true,
        });
      }
    },
    cancelForm() {
      this.loading = false;
      this.drawer = false;
      clearTimeout(this.timer);
    },
    postallyear() {
      this.selectIndexEntity.particular = this.allyear;
    },
    clearallyear() {
      this.selectIndexEntity.particular = [];
    },
    async getComprehensivedata() {
      const res = await this.axios.get("/humanCapital/selectComprehensive", {
        params: {
          pid: this.pid,
        },
      });
      if (res.code == 200) {
        this.comprehensivedata = res.object;
      }
    },
    setComprehensiveId(data, name) {
      this.pid = data;
      this.Comprehensive.comprehensiveId = data;
      this.Comprehensive.comprehensivetypeName = name;
      this.indexEntity.particular = this.year;
      this.indexEntity.comprehensiveId = this.Comprehensive.comprehensiveId;
      this.getworld();
      this.getSCE();
    },
    setyear(data) {
      this.year = data;
      this.indexEntity.particular = this.year;
      this.indexEntity.comprehensiveId = this.Comprehensive.comprehensiveId;
      this.getworld();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.countriess : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      // this.checkedCities.splice(0,this.checkedCities.length);
      // console.log(this.checkedCities)
      this.checkedCities = value;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.countriess.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.countriess.length;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    gettoken() {
      const atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.userData = null;
        this.token = null;
      }
    },
    async getuserData() {
      if (this.token == null) {
      } else {
        const res = await this.axios.get("/basicUser/tokenByUser", {
          params: {
            token: this.token,
          },
        });
        if (res.code == 200) {
          this.userData = res.object;
        } else {
          this.userData = null;
        }
      }
    },
    //世界地图
    async getworld() {
      const chartDom = document.getElementById("main3");
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart) {
        myChart.dispose(); //销毁
      }
      myChart = echarts.init(chartDom);
      myChart.clear();
      var option;
      myChart.showLoading();
      const resw = await this.axios.post(
        "/humanCapital/select",
        this.indexEntity
      );
      this.worlddata = resw.object;
      const res2 = await this.axios.get("/humanCapital/selectTime");
      this.allyear = res2.object;
      myChart.hideLoading();
      echarts.registerMap("World", world);
      option = {
        globe: {
          baseTexture: this.heightTextureSrc,
          heightTexture: this.heightTextureSrc,
          displacementScale: 0.04,
          shading: "realistic",
          // environment: this.environmentSrc,
          environment: this.environmentSrc,
          realisticMaterial: {
            roughness: 0.9,
          },
          postEffect: {
            enable: true,
          },
          light: {
            main: {
              intensity: 5,
              shadow: true,
            },
            ambientCubemap: {
              texture:
                "https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples/data-gl/asset/pisa.hdr",
              diffuseIntensity: 0.2,
            },
          },
        },
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
        },

        visualMap: {
          left: "left",
          min: 0,
          max: 100,
          inRange: {
            color: [
              "#313695",
              "#4575b4",
              "#74add1",
              "#abd9e9",
              "#e0f3f8",
              "#ffffbf",
              "#fee090",
              "#fdae61",
              "#f46d43",
              "#d73027",
              "#a50026",
            ],
          },
          text: ["High", "Low"],
          calculable: true,
        },
        toolbox: {
          show: true,
          //orient: 'vertical',
          left: "right",
          top: "top",
          feature: {
            dataView: { readOnly: false },
            // restore: {},
            saveAsImage: {},
          },
        },
        series: [
          {
            name: this.Comprehensive.comprehensivetypeName,
            id: this.Comprehensive.comprehensiveId,
            type: "map",
            roam: true,
            map: "World",
            emphasis: {
              label: {
                show: true,
              },
            },
            data: this.worlddata,
            nameMap: {
              Somalia: "索马里",
              Liechtenstein: "列支敦士登",
              Morocco: "摩洛哥",
              "W. Sahara": "西撒哈拉",
              Serbia: "塞尔维亚",
              Afghanistan: "阿富汗",
              Angola: "安哥拉",
              Albania: "阿尔巴尼亚",
              Andorra: "安道尔共和国",
              "United Arab Emirates": "阿拉伯联合酋长国",
              Argentina: "阿根廷",
              Armenia: "亚美尼亚",
              Australia: "澳大利亚",
              Austria: "奥地利",
              Azerbaijan: "阿塞拜疆",
              Burundi: "布隆迪",
              Belgium: "比利时",
              Benin: "贝宁",
              "Burkina Faso": "布基纳法索",
              Bangladesh: "孟加拉国",
              Bulgaria: "保加利亚",
              Bahrain: "巴林",
              Bahamas: "巴哈马",
              "Bosnia and Herz.": "波斯尼亚和黑塞哥维那",
              Belarus: "白俄罗斯",
              Belize: "伯利兹",
              Bermuda: "百慕大",
              Bolivia: "玻利维亚",
              Brazil: "巴西",
              Barbados: "巴巴多斯",
              Brunei: "文莱",
              Bhutan: "不丹",
              Botswana: "博茨瓦纳",
              "Central African Republic": "中非",
              Canada: "加拿大",
              Switzerland: "瑞士",
              Chile: "智利",
              China: "中国",
              "Ivory Coast": "科特迪瓦",
              Cameroon: "喀麦隆",
              "Democratic Republic of the Congo": "刚果民主共和国",
              "Republic of the Congo": "刚果",
              Colombia: "哥伦比亚",
              "Cape Verde": "佛得角",
              "Costa Rica": "哥斯达黎加",
              Cuba: "古巴",
              "N. Cyprus": "北塞浦路斯",
              Cyprus: "塞浦路斯",
              "Czech Rep.": "捷克",
              Germany: "德国",
              Djibouti: "吉布提",
              Denmark: "丹麦",
              "Dominican Rep.": "多米尼加",
              Algeria: "阿尔及利亚",
              Ecuador: "厄瓜多尔",
              Egypt: "埃及",
              Eritrea: "厄立特里亚",
              Spain: "西班牙",
              Estonia: "爱沙尼亚",
              Ethiopia: "埃塞俄比亚",
              Finland: "芬兰",
              Fiji: "斐济",
              France: "法国",
              Gabon: "加蓬",
              "United Kingdom": "英国",
              Georgia: "格鲁吉亚",
              Ghana: "加纳",
              Guinea: "几内亚",
              Gambia: "冈比亚",
              "Guinea-Bissau": "几内亚比绍",
              "Eq. Guinea": "赤道几内亚",
              Greece: "希腊",
              Grenada: "格林纳达",
              Greenland: "格陵兰",
              Guatemala: "危地马拉",
              Guam: "关岛",
              Guyana: "圭亚那",
              Honduras: "洪都拉斯",
              Croatia: "克罗地亚",
              Haiti: "海地",
              Hungary: "匈牙利",
              Indonesia: "印度尼西亚",
              India: "印度",
              "Br. Indian Ocean Ter.": "英属印度洋领土",
              Ireland: "爱尔兰",
              Iran: "伊朗",
              Iraq: "伊拉克",
              Iceland: "冰岛",
              Israel: "以色列",
              Italy: "意大利",
              Jamaica: "牙买加",
              Jordan: "约旦",
              Japan: "日本",
              "North Korea": "朝鲜",
              "Siachen Glacier": "锡亚琴冰川",
              Kazakhstan: "哈萨克斯坦",
              Kenya: "肯尼亚",
              Kyrgyzstan: "吉尔吉斯坦",
              Cambodia: "柬埔寨",
              "South Korea": "韩国",
              Kuwait: "科威特",
              "Lao PDR": "老挝",
              Lebanon: "黎巴嫩",
              Liberia: "利比里亚",
              Libya: "利比亚",
              "Sri Lanka": "斯里兰卡",
              Lesotho: "莱索托",
              Lithuania: "立陶宛",
              Luxembourg: "卢森堡",
              Latvia: "拉脱维亚",
              Moldova: "摩尔多瓦",
              Madagascar: "马达加斯加",
              Mexico: "墨西哥",
              Macedonia: "马其顿",
              Mali: "马里",
              Malta: "马耳他",
              Myanmar: "缅甸",
              Montenegro: "黑山",
              Mongolia: "蒙古",
              Mozambique: "莫桑比克",
              Mauritania: "毛里塔尼亚",
              Mauritius: "毛里求斯",
              Malawi: "马拉维",
              Malaysia: "马来西亚",
              Namibia: "纳米比亚",
              "New Caledonia": "新喀里多尼亚",
              Niger: "尼日尔",
              Nigeria: "尼日利亚",
              Nicaragua: "尼加拉瓜",
              Netherlands: "荷兰",
              Norway: "挪威",
              Nepal: "尼泊尔",
              "New Zealand": "新西兰",
              Oman: "阿曼",
              Pakistan: "巴基斯坦",
              Panama: "巴拿马",
              Peru: "秘鲁",
              Philippines: "菲律宾",
              "Papua New Guinea": "巴布亚新几内亚",
              Poland: "波兰",
              "Puerto Rico": "波多黎各",
              "Dem. Rep. Korea": "朝鲜",
              Portugal: "葡萄牙",
              Paraguay: "巴拉圭",
              Palestine: "巴勒斯坦",
              Qatar: "卡塔尔",
              Romania: "罗马尼亚",
              Russia: "俄罗斯",
              Rwanda: "卢旺达",
              "Saudi Arabia": "沙特阿拉伯",
              Sudan: "苏丹",
              "South Sudan": "南苏丹",
              Senegal: "塞内加尔",
              Singapore: "新加坡",
              "Solomon Is.": "所罗门群岛",
              "Sierra Leone": "塞拉利昂",
              "El Salvador": "萨尔瓦多",
              Suriname: "苏里南",
              Slovakia: "斯洛伐克",
              Slovenia: "斯洛文尼亚",
              Sweden: "瑞典",
              Swaziland: "斯威士兰",
              Seychelles: "塞舌尔",
              Syria: "叙利亚",
              Chad: "乍得",
              Togo: "多哥",
              Thailand: "泰国",
              Tajikistan: "塔吉克斯坦",
              Turkmenistan: "土库曼斯坦",
              "Timor-Leste": "东帝汶",
              Tonga: "汤加",
              "Trinidad and Tobago": "特立尼达和多巴哥",
              Tunisia: "突尼斯",
              Turkey: "土耳其",
              "United Republic of Tanzania": "坦桑尼亚",
              Uganda: "乌干达",
              Ukraine: "乌克兰",
              Uruguay: "乌拉圭",
              "United States of America": "美国",
              Uzbekistan: "乌兹别克斯坦",
              Venezuela: "委内瑞拉",
              Vietnam: "越南",
              Vanuatu: "瓦努阿图",
              Yemen: "也门",
              "South Africa": "南非",
              Zambia: "赞比亚",
              Zimbabwe: "津巴布韦",
              Aland: "奥兰群岛",
              "American Samoa": "美属萨摩亚",
              "Fr. S. Antarctic Lands": "南极洲",
              "Antigua and Barb.": "安提瓜和巴布达",
              Comoros: "科摩罗",
              Curaçao: "库拉索岛",
              "Cayman Is.": "开曼群岛",
              Dominica: "多米尼加",
              "Falkland Is.": "马尔维纳斯群岛（福克兰）",
              "Faeroe Is.": "法罗群岛",
              Micronesia: "密克罗尼西亚",
              "Heard I. and McDonald Is.": "赫德岛和麦克唐纳群岛",
              "Isle of Man": "曼岛",
              Jersey: "泽西岛",
              Kiribati: "基里巴斯",
              "Saint Lucia": "圣卢西亚",
              "N. Mariana Is.": "北马里亚纳群岛",
              Montserrat: "蒙特塞拉特",
              Niue: "纽埃",
              Palau: "帕劳",
              "Fr. Polynesia": "法属波利尼西亚",
              "S. Geo. and S. Sandw. Is.": "南乔治亚岛和南桑威奇群岛",
              "Saint Helena": "圣赫勒拿",
              "St. Pierre and Miquelon": "圣皮埃尔和密克隆群岛",
              "São Tomé and Principe": "圣多美和普林西比",
              "Turks and Caicos Is.": "特克斯和凯科斯群岛",
              "St. Vin. and Gren.": "圣文森特和格林纳丁斯",
              "U.S. Virgin Is.": "美属维尔京群岛",
              Samoa: "萨摩亚",
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener(
        "resize",
        () => {
          myChart.resize();
        },
        { passive: false }
      );
      myChart.on("click", (params) => {
        const city = params.name;
        this.childdata.name = city;
        this.childdata.pid = params.seriesId;
        this.childdata.comprehensivetypeName =
          this.Comprehensive.comprehensivetypeName;
        this.childdata.comprehensiveId = this.Comprehensive.comprehensiveId;
        this.$router.push({
          path: "/HumanCapitalizationDisplay/subData",
          query: {
            cdata: JSON.stringify(this.childdata),
            path: this.$route.path,
          },
        });
      });
    },
    //区域人力资本化指数
    async getradar() {
      const chartDom = document.getElementById("main2");
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart) {
        myChart.dispose(); //销毁
      }
      myChart = echarts.init(chartDom);
      var option;
      myChart.showLoading();
      {
        myChart.hideLoading();
        echarts.registerMap("china", china);
        myChart.setOption(
          (option = {
            title: {
              text: "区域人力资本化指数",
              subtext: "数据来源：四川易铁必肯",
            },
            tooltip: {
              trigger: "item",
              formatter: "{b}<br/>{c} ",
            },
            toolbox: {
              show: true,
              orient: "vertical",
              left: "right",
              top: "center",
              feature: {
                dataView: { readOnly: false },
                restore: {},
                saveAsImage: {},
              },
            },
            visualMap: {
              min: 800,
              max: 6000000,
              text: ["High", "Low"],
              calculable: true,
              realtime: true,
              inRange: {
                color: [
                  "#313695",
                  "#4575b4",
                  "#74add1",
                  "#abd9e9",
                  "#e0f3f8",
                  "#ffffbf",
                  "#fee090",
                  "#fdae61",
                  "#f46d43",
                  "#d73027",
                  "#a50026",
                ],
              },
            },
            series: [
              {
                name: "",
                type: "map",
                map: "china",
                zoom: 1, //当前视角的缩放比例
                roam: true, //是否开启平游或缩放
                scaleLimit: {
                  //滚轮缩放的极限控制
                  min: 1,
                  max: 4,
                },
                label: {
                  show: true,
                },
                data: [
                  { name: "北京市", value: 4822023 },
                  { name: "天津市", value: 2822023 },
                  { name: "上海市", value: 4822023 },
                  { name: "重庆市", value: 731449 },
                  { name: "河北省", value: 4553255 },
                  { name: "河南省", value: 2949131 },
                  { name: "云南省", value: 2804143 },
                  { name: "辽宁省", value: 2187582 },
                  { name: "黑龙江省", value: 3590347 },
                  { name: "湖南省", value: 917092 },
                  { name: "安徽省", value: 632323 },
                  { name: "山东省", value: 1931756 },
                  { name: "新疆维吾尔自治区", value: 119945 },
                  { name: "江苏省", value: 1392313 },
                  { name: "浙江省", value: 4595728 },
                  { name: "江西省", value: 1287525 },
                  { name: "湖北省", value: 3537334 },
                  { name: "广西壮族自治区", value: 3074186 },
                  { name: "甘肃省", value: 2885905 },
                  { name: "山西省", value: 1380415 },
                  { name: "内蒙古自治区", value: 1601893 },
                  { name: "陕西省", value: 1329192 },
                  { name: "吉林省", value: 3884563 },
                  { name: "福建省", value: 2646144 },
                  { name: "贵州省", value: 3883360 },
                  { name: "广东省", value: 4379139 },
                  { name: "青海省", value: 2984926 },
                  { name: "西藏自治区", value: 1021988 },
                  { name: "四川省", value: 1005141 },
                  { name: "宁夏回族自治区", value: 1855525 },
                  { name: "海南省", value: 1320718 },
                  { name: "台湾省", value: 3864590 },
                  { name: "香港省", value: 2085538 },
                  { name: "澳门省", value: 1957026 },
                ],
                // 自定义名称映射
              },
            ],
          })
        );
      }
      myChart.setOption(option);
      window.addEventListener(
        "resize",
        () => {
          myChart.resize();
        },
        { passive: false }
      );
      myChart.on("click", (params) => {
        const city = params.name;
        alert(city);
      });
    },

    //全球人力资本化折线图
    async getSCE() {
      const chartDom = document.getElementById("main");
      // const chartDom1 = document.getElementById("bmain");

      // if (this.checkedCities.length <= 20) {
      let myChart = echarts.getInstanceByDom(chartDom);
      if (myChart) {
        myChart.dispose(); //销毁
      }
      myChart = echarts.init(chartDom);
      myChart.resize;
      var option;

      const res = await this.axios.get("/humanCapital/selectComprehensiveZ", {
        params: {
          comprehensiveId: this.indexEntity.comprehensiveId,
        },
      });
      {
        if (res) {
          const res1 = await this.axios.get("/humanCapital/selectCountry");
          this.countriess = res1.object;
          const countries = this.checkedCities;
          const selected = {};
          for (let i = 0; i < countries.length; i++) {
            if (i <= 4) {
              selected[countries[i]] = true;
            } else {
              selected[countries[i]] = false;
            }
          }
          const datasetWithFilters = [];
          const seriesList = [];
          echarts.util.each(countries, function (name) {
            const datasetId = "dataset_" + name;
            datasetWithFilters.push({
              id: datasetId,
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "particular", gte: 1980 },
                    { dimension: "name", "=": name },
                  ],
                },
              },
            });
            seriesList.push({
              type: "line",
              datasetId: datasetId,
              showSymbol: false,
              name: name,
              endLabel: {
                show: true,
                formatter: function (params) {
                  return params.value[2] + ": " + params.value[0];
                },
              },
              labelLayout: {
                moveOverlap: "shiftY",
              },
              emphasis: {
                focus: "series",
              },
              encode: {
                x: "particular",
                y: "value",
                label: ["name", "value"],
                itemName: "particular",
                tooltip: ["value"],
              },
            });
          });
          option = {
            animationDuration: 10000,
            dataset: [
              {
                id: "dataset_raw",
                source: res.object,
              },
              ...datasetWithFilters,
            ],
            // title: {
            //   text: '国家人力资本化数据'
            // },
            toolbox: {
              feature: {
                dataZoom: {
                  yAxisIndex: "none",
                },
                // restore: {},
                saveAsImage: {},
              },
            },
            legend: {
              selected: selected,
            },
            tooltip: {
              order: "valueDesc",
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              nameLocation: "middle",
            },
            yAxis: {
              name: "value",
            },
            grid: {
              right: 140,
            },
            series: seriesList,
          };
          myChart.setOption(option);
          window.onresize = myChart.resize;
        }
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-link.el-link--default:hover {
  color: rgb(218, 185, 107);
}

::v-deep .el-drawer.btt,
.el-drawer.ttb {
  height: 100% !important;
  width: 100%;
}

.main {
  margin: 50px;
  width: 90%;
}
::v-deep .el-col-2 {
  padding: 10px;
}
.title-chart {
  font-size: 36px;
  font-weight: bolder;
  color: #0b1013;
  font-family: "方正大标宋简体";
}

::v-deep .el-drawer.ttb {
  width: 100%;
  height: 50% !important;
  left: 0;
  right: 0;
}

::v-deep .el-drawer__header {
  margin: 0 !important;
  padding: 0 !important;
}

.el-button--primary {
  color: #fff;
  background-color: #dab96b;
  border-color: #dab96b;
}

#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}

html,
body {
  height: 100%;
}

.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}

::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}

.background {
  background-color: #f7f7f7;
  height: 100px;
}

hr {
  margin: 0;
  margin-bottom: 8px;
}

.stbox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.stbgc {
  background-color: #fff;
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  padding: 0px 16px;
}

.stsearch {
  text-align: center;
}

.text-c {
  text-align: center;
}

.st-gbox {
  background-color: #fff;
  margin-top: 20px;
  border-radius: 5px;
  height: 30vh;
  box-sizing: border-box;
  padding: 10px;
}

.cavasbox {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.download {
  width: 24%;
  height: 350px;
  margin: 50px 5px 50px 0;
  background-color: #f5f5f5;
}

.downTitle {
  width: 90%;
  height: 50px;
  margin: 10px 0px 0px 30px;
}

.paybox {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
  height: 32vh;
}
</style>